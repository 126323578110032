<template>
  <form action="/recherche" accept-charset="UTF-8" style="margin-top: 30px;">
    <button class="btn_full btn_1 outline" type="submit">
      <template v-if="checkin">
        <span class="hidden-md">Voir </span>
        + d'hôtels à ces dates
      </template>
      <template v-else>
        Voir + d'hôtels
      </template>
    </button>

    <input type="hidden" name="search[id]" :value="searchId" />
    <input type="hidden" name="search[checkin]" :value="checkin" />
    <input type="hidden" name="search[checkout]" :value="checkout" />
    <input type="hidden" name="search[adults]" :value="adults" />
    <input type="hidden" name="search[hotels_no_subset_verify]" value="1" />
    <input type="hidden" name="search[filters_from_session]" value="1" />
  </form>
</template>

<script>
export default {
  name: "BroaderButton",
  props: ["checkin", "checkout", "adults", "searchId"],
};
</script>
