<template>
  <div class="sidebar">
    <div class="main-params">
      <form action="/recherche" v-on:submit.prevent="submit" id="search-form">
        <input type="hidden" name="search[checkin]" :value="checkin" />
        <input type="hidden" name="search[checkout]" :value="checkout" />
        <div id="main_params" class="filter_type">
          <div class="form-group">
            <label>
              <i class="icon-calendar-7" />
              Dates de séjour
            </label>
            <DateRangeInput
              :checkin="checkin"
              :checkout="checkout"
              @dates-change="handleDatesChange"
              ref="dateRangeInput"
            />
          </div>

          <div class="form-group">
            <label>
              <i class="icon-adult" />
              Nombre de personnes
            </label>
            <QuantityChange
              name="search[adults]"
              v-bind:initial-value="this.adults"
              v-bind:min="1"
              v-bind:max="4"
              @change="handleAdultsChanged"
            />
          </div>

          <div class="form-group">
            <input
              type="checkbox"
              id="booking-consent"
              v-model="bookingConsent"
              checked
            />
            <label class="font-weight-normal" for="booking-consent">
              Chercher aussi sur Booking.com
            </label>
          </div>

          <div class="group-link">
            <label>8 chambres ou plus ?</label>
            <br />
            <a :href="groupUrlBase" rel="nofollow noopener" target="_blank"
              >Optez pour un devis personnalisé.</a
            >
          </div>

          <br />
          <Button :isLoading="isLoading" fullWidth />
        </div>

        <h6
          class="filters-title visible-xs visible-sm"
          v-on:click.prevent="expanded = !expanded"
        >
          <i class="icon-params" />
          {{ filterTitle }}
          <i
            :class="{
              arrow: true,
              'icon-down-open-mini': expanded,
              'icon-right-open-mini': !expanded,
              'pull-right': true,
            }"
          />
        </h6>

        <div v-if="expanded">
          <FilterGroup
            title="Standing"
            name="standings"
            :filters="filtersStanding"
            :onChange="handleFilterChanged"
          />

          <PriceRange
            :initialValue="initialPriceRange"
            :onChange="handleFilterChanged"
          />

          <FilterGroup
            title="Services"
            name="facilities"
            :filters="filtersFacilities"
            :onChange="handleFilterChanged"
          />

          <FilterGroup
            title="Quartiers"
            name="zones"
            :filters="filtersZones"
            :onChange="handleFilterChanged"
          />

          <br />
          <Button :isLoading="isLoading" fullWidth />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import EventBus from "../../EventBus";
import DateRangeInput from "../../shared/DateRangeInput";
import QuantityChange from "../../shared/quantity_change/index.vue";
import Button from "../../shared/SearchButton";
import FilterGroup from "./FilterGroup";
import PriceRange from "./PriceRange";
import bookingSearchExtensionMixin from "../../mixins/bookingSearchExtensionMixin";
import { elementTopIntoView, scrollTopIntoView } from "../../../utils/ui";

export default {
  name: "SearchForm",
  props: [
    "initialCheckin",
    "initialCheckout",
    "initialAdults",
    "initialPriceRange",
    "groupUrlBase",
    "filtersStanding",
    "filtersFacilities",
    "filtersZones",
  ],

  components: {
    Button,
    DateRangeInput,
    QuantityChange,
    FilterGroup,
    PriceRange,
  },

  mounted() {
    window.addEventListener("resize", this.resetExpanded, true);
  },

  destroyed() {
    window.removeEventListener("resize", this.resetExpanded);
  },

  mixins: [bookingSearchExtensionMixin],

  methods: {
    handleDatesChange({ checkin, checkout }) {
      if (this.checkin || this.checkout) {
        this.paramsChanged = true;
      }

      this.checkin = checkin;
      this.checkout = checkout;
    },

    handleAdultsChanged(adults) {
      if (this.adults) {
        this.paramsChanged = true;
      }

      this.adults = adults;
    },

    async pushState(state) {
      if (state.url === window.location.pathname + window.location.search) {
        return;
      }

      window.history.pushState(state, null, state.url);

      if (window.ga) {
        window.ga("send", "pageview", state.url);
      }
    },

    submit() {
      if (!this.$refs.dateRangeInput.isValid()) {
        return;
      }

      this.isLoading = true;

      const params = new URLSearchParams();
      params.append("search[params_changed]", this.paramsChanged);

      const form = document.forms["search-form"];
      const body = new FormData(form);
      Array.from(body.entries()).forEach(([key, value]) => {
        params.append(key, value);
      });

      EventBus.$emit("search-submit", {
        checkin: this.checkin,
        checkout: this.checkout,
        adults: this.adults,
      });

      const finallyFn = ({ url }) => {
        this.isLoading = false;
        this.pushState({ url });
      };

      EventBus.$on("search-update-finished", finallyFn);

      EventBus.$emit("search-update-success", {
        checkin: this.checkin,
        checkout: this.checkout,
        adults: this.adults,
        formParams: params,
      });

      const searchResults = document.getElementById("search-results");
      if (!elementTopIntoView(searchResults)) {
        scrollTopIntoView(searchResults);
      }
    },

    handleFilterChanged() {
      this.debouncedSubmit();
    },

    resetExpanded() {
      this.expanded = this.detectWindowWidth() >= 992;
      return this.expanded;
    },

    detectWindowWidth() {
      return (
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      );
    },
  },

  data() {
    return {
      expanded: this.resetExpanded(),
      checkin: this.initialCheckin,
      checkout: this.initialCheckout,
      adults: this.initialAdults || 2,
      paramsChanged: false,
      isLoading: false,
      debouncedSubmit: debounce(this.submit, 1500),
    };
  },

  beforeDestroy() {
    this.debouncedSubmit.cancel();
  },

  computed: {
    filterTitle() {
      if (this.expanded) return "Filtres";
      return "Filtrer par prix, services…";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_variables";

.sidebar {
  background-color: $white;
  padding: $spacing * 2;
  border: 1px solid $border-light;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: $spacing * 3;
}

.arrow {
  position: relative;
  bottom: 3px;
  font-size: $size4;
}

.filters-title {
  display: block;
  padding: $spacing 0;
  margin: $spacing * 4 0 $spacing;
  cursor: pointer;
}

.main-params {
  hr {
    color: $border-light;
    margin: $spacing * 2 0;
  }
}

.group-link {
  label {
    font-weight: normal;
    color: $disabled;
    margin-bottom: 0;
  }
}

.filters {
  margin-top: $spacing * 4;
}

.font-weight-normal {
  font-weight: normal;
}
</style>
