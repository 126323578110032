export default {
  data() {
    return {
      bookingConsent: true,
      bookingExtensionInitialized: false,
      hiddenInputsCreated: false,
    };
  },

  methods: {
    createBookingUrl() {
      const url = new URL("https://www.booking.com/searchresults.html");
      const params = {
        aid: window.gon.booking_aid,
        label: "search-extension-fallback",
        checkin: this.checkin,
        checkout: this.checkout,
        group_adults: this.adults,
      };

      // Utiliser la valeur du champ caché s'il existe
      const hiddenIata = document.getElementById("hidden-booking-iata");
      const hiddenSs = document.getElementById("hidden-booking-ss");

      if (hiddenIata && hiddenIata.value) {
        params.iata = hiddenIata.value;
        params.iata_orr = "1";
      } else if (hiddenSs && hiddenSs.value) {
        params.ss = hiddenSs.value;
      } else if (window.gon.iata) {
        params.iata = window.gon.iata;
        params.iata_orr = "1";
      } else if (window.gon.dst_fallback) {
        params.ss = window.gon.dst_fallback;
      }

      Object.entries(params).forEach(([key, value]) => {
        if (value) url.searchParams.set(key, value);
      });

      return url;
    },

    createSearchUrl() {
      const url = new URL("/recherche", window.location.origin);
      const form = document.getElementById("search-form");
      const formData = new FormData(form);

      formData.forEach((value, key) => {
        if (value) url.searchParams.set(key, value);
      });

      url.hash = "search-results";
      return url;
    },

    createHiddenInputs() {
      if (this.hiddenInputsCreated) return;
      this.hiddenInputsCreated = true;

      const form = document.getElementById("search-form");
      if (!form) return;

      // Créer le champ caché pour IATA s'il y a une valeur
      if (window.gon.iata) {
        if (!document.getElementById("hidden-booking-iata")) {
          const iataInput = document.createElement("input");
          iataInput.type = "hidden";
          iataInput.id = "hidden-booking-iata";
          iataInput.name = "hidden-booking-iata";
          iataInput.value = window.gon.iata;
          form.appendChild(iataInput);
        }
      }
      // Créer le champ caché pour destination si pas d'IATA
      else if (window.gon.dst_fallback) {
        if (!document.getElementById("hidden-booking-ss")) {
          const ssInput = document.createElement("input");
          ssInput.type = "hidden";
          ssInput.id = "hidden-booking-ss";
          ssInput.name = "hidden-booking-ss";
          ssInput.value = window.gon.dst_fallback;
          form.appendChild(ssInput);
        }
      }
    },

    setupBookingExtension() {
      this.updateFormTarget();

      // Créer les champs cachés
      this.createHiddenInputs();

      // Évite les appels multiples
      if (this.bookingExtensionInitialized) {
        return;
      }

      // Configuration de l'event listener une seule fois
      const searchButton = document.getElementById("search-submit");
      if (searchButton) {
        searchButton.addEventListener("click", this.handleSearchClick);
      }

      // Configuration de bookingLeaveBehind avec les champs cachés
      if (window.bookingLeaveBehind) {
        const params = {
          aid: window.gon.booking_aid,
          hostname: "sp.booking.com",
          label: "search-extension",
          checkin: '#search-form input[name="search[checkin]"]',
          checkout: '#search-form input[name="search[checkout]"]',
          consent: "#booking-consent",
          group_adults: '#search-form input[name="search[adults]"]',
        };

        // Utiliser les sélecteurs des champs cachés
        if (window.gon.iata) {
          params.iata = "#hidden-booking-iata";
        } else if (window.gon.dst_fallback) {
          params.ss = "#hidden-booking-ss";
        }

        window.bookingLeaveBehind.on(
          "click",
          "#search-submit",
          this.createBookingUrl(),
          params
        );
      }

      this.bookingExtensionInitialized = true;
    },

    updateFormTarget() {
      const form = document.getElementById("search-form");
      if (!form) return;

      if (!this.bookingConsent || !this.hasValidDestination()) {
        form.removeAttribute("target");
      } else {
        form.setAttribute("target", "_blank");
      }
    },

    handleSearchClick(event) {
      if (this.bookingConsent && this.hasValidDestination()) {
        event.preventDefault();
        window.open(this.createSearchUrl().toString());
      }
    },

    hasValidDestination() {
      return window.gon?.iata || window.gon?.dst_fallback;
    },
  },

  watch: {
    bookingConsent: {
      handler() {
        this.updateFormTarget();
      },
      immediate: true,
    },
  },

  mounted() {
    // S'assurer que les champs cachés sont créés même si bookingLeaveBehind
    // n'est pas encore chargé
    this.createHiddenInputs();

    if (window.bookingLeaveBehind) {
      this.setupBookingExtension();
    } else {
      // Attendre que bookingLeaveBehind soit disponible
      const checkBookingInterval = setInterval(() => {
        if (window.bookingLeaveBehind) {
          clearInterval(checkBookingInterval);
          this.setupBookingExtension();
        }
      }, 100);

      // Nettoyer l'intervalle après un délai raisonnable (5 secondes)
      setTimeout(() => {
        clearInterval(checkBookingInterval);
      }, 5000);

      // Fallback pour garantir l'initialisation
      window.addEventListener("load", () => {
        if (window.bookingLeaveBehind && !this.bookingExtensionInitialized) {
          this.setupBookingExtension();
        }
      });
    }
  },

  beforeUnmount() {
    const searchButton = document.querySelector("#search-submit");
    if (searchButton) {
      searchButton.removeEventListener("click", this.handleSearchClick);
    }
  },
};
