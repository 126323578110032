<template lang="html">
  <button
    id="search-submit"
    :class="{ btn_full: fullWidth, btn_1: !fullWidth, green: !fullWidth }"
    :disabled="isLoading"
  >
    <Loader v-if="isLoading" />
    <span :class="{ invisible: isLoading }">
      <i class="icon-search" />
      Chercher
    </span>
  </button>
</template>

<script>
import Loader from "../Loader";

export default {
  name: "Button",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Loader,
  },
};
</script>

<style lang="scss" scoped>
.btn_1 {
  position: relative;
}

.invisible {
  visibility: hidden;
}
.icon-spinner {
  position: absolute;
  left: 0;
  right: 0;
}
</style>
