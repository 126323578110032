/* eslint-disable no-unused-vars, no-new */

import Vue from "vue";
import SearchForm from "./index.vue";

document.addEventListener("js-ready", () => {
  const container = "search-form-container";

  if (document.getElementById(container)) {
    new Vue({
      el: `#${container}`,
      components: { SearchForm },
      data() {
        return {};
      },
    });
  }
});
